.wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login {
  display: flex;
  flex-direction: column; 
  align-items: center;
  width: 350px;
  max-width: 90%;
}

.button {
  padding: 10px 0 20px 0;
}

.logoWrapper {
  margin-top: -1rem;
}

.appName {
  margin-top: 0;
}