body {
    font-family: 'Helvetica neue', 'Arial', sans-serif !important;
}
// h1, h2, h3, h4, h5 {
//   font-family: fira-sans;
//   font-weight: 600;
//   // font-style: italic;
// }

// body,
// .ui.menu,
// .ui.input>input,
// .Toastify__toast,
// .ui.button {
//   font-family: fira-sans;
// }

// .ui.button,
// .ui.secondary.pointing.menu .active.item {
//   font-weight: 600;
// }

.ui-alerts {
    pointer-events: none;

    .ui.message {
        padding-right: 30px;

        i:nth-child(2) {
            display: none !important;
        }
    }
}
